import React from "react";
import Page from "../components/Page";
import successImage from "../images/what-is-success.jpg";
import { graphql } from "gatsby";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image"

export default function Målsättning({ data }) {
  const image = data.image.childImageSharp.gatsbyImageData;

  const title = "Målsättning: Hur du sätter och uppnår dina mål!"

  const schema = {
    "@context": "https://schema.org/",
    "@type": "FAQPage",
    "mainEntity": [
      {
        "@type": "Question",
        "name": "Vad är smarta mål?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Smarta mål kan hjälpa oss att arbeta mer systematiskt med vår målsättning vilket kan öka sannolikheten för att vi uppnår våra mål och dessutom kan smarta mål bidra med mycket motivation. De fem principer som smarta mål kännetecknas av är: specifika, mätbara, acceptabla, realistiska och tidsbegränsade."
        }
      },
      {
        "@type": "Question",
        "name": "Varför ska man sätta mål?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Om du vill uppnå något i livet så måste du ha egna mål, annars finns det en stor risk att du kommer hjälpa någon annan att uppfyllas deras mål."
        }
      },
      {
        "@type": "Question",
        "name": "Vilka olika typer av mål finns?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Det finns många olika typer av mål inom målsättning, såsom tidsmål, fokusmål, ämnesbaserade mål och beteendemål. I många fall kan ett mål innefattas i flera av kategorierna, men det gör inte alltid det."
        }
      }
    ]
  }

  
  return (
    <>
      <Page title={title} description="Att sätta mål är otroligt viktigt för att skapa något att sträva efter i våra liv. Men hur sätter man mål och vad skall man tänka på?" image={image?.images.fallback.src} schema={schema}>
        <div className="container px-5 py-6 mx-auto">
          <article className="blogPost">
            {image && <GatsbyImage image={image} className="mb-5 rounded-xl" alt="målsättning" />}
            <h1 className="font-bold text-4xl md:text-5xl text-gray-900 leading-tight mb-8">{title}</h1>
            <p>
              Idag går många människor i sömnen genom sina liv. Även om de arbetar hårt, känner de att de inte får ut tillräckligt av livet. Anledning till varför många känner så är att det inte har en klar riktning vart de vill ta sig i livet och vad de vill uppnå.
            </p>
            <p>
              Om du vill uppnå något i livet så måste du ha egna mål, annars finns det en stor risk att du kommer hjälpa någon annan att uppfyllas deras mål. Om du tycker att det är okej kan du sluta läsa nu, men om du däremot strävar efter något mer i livet kan du fortsätta läsa.
            </p>
            <p>
              Vart vill du vara om 5 år? Detta är en bra fråga att ställa sig själv och kan fungera som grunden till de målen som du sätter för dig själv. Baserat på var du vill om 5 år kan du sätta upp en handlingsplan om hur du skall ta dig dit, och detta är precis det som mål handlar om. Mål skall ge dig en riktning till vart du är påväg.
            </p>
            <blockquote>When you don’t set goals, you can spend your whole life running up and down and not achieve anything. <br /> - Bill Copeland</blockquote>
            <h2>Viktiga principer för målsättning</h2>
            <p>Mycket forskning har gjorts inom målsättning och hur man sätter mer effektiva mål som ger oss resultat. Tidigare gjordes mycket av forskning på målsättning för att se sambandet mellan den avsedda prestationen och det slutgiltiga resultatet. Något som visades tydligt var att väldefinierade mål ledde till större resultat.</p>
            <p>Vid 1990-talet föreslogs också fem nyckelprinciper för att sätta mer framgångsrika mål (Locke & Latham, 1990).</p>
            <GatsbyImage image={data.principer?.childImageSharp.gatsbyImageData} alt="framgångsrik målsättning" />
            <h3>1. Engagemang</h3>
            <p>Engagemang avser i vilken utsträckning som en individ är knuten till målet, samt deras beslutsamhet att nå det. Enligt Locke & Latham (1990) är målprestationen starkast när människor är engagerade, och detta gäller ännu mer när målen är svåra.</p>
            <p>Om vi däremot skulle vara mindre engagerade i våra mål, särskilt om målet är utmanade, så ökar sannolikheten att vi ger upp. Om vi däremot är mycket engagerade så kommer vi göra allt för att uppnå vårt mål. Exempelvis om vi skulle upptäcka att vårt arbete är lägre än det som krävs, så kommer vi sannolikt att öka vår ansträngning eller ändra vår strategi för att uppnå målet.</p>
            <h3>2. Klarhet</h3>
            <p>Specifika mål sätt dig på en direkt kurs. Forskning från Arvey, Dewhirst & Boling (1976) indikerade bland annat att målklarhet är positivt relaterad till övergripande motivation, och att mål som är vaga har ett begränsat motivationsvärde.</p>
            <p>Så sätt upp tydliga och exakta mål som kan mätas. När du har klarhet kring ditt mål kommer du ha en bättre förståelse för arbetet och du vet vad som krävs från dig. </p>
            <h3>3. Utmaning</h3>
            <p>Även om mål skall vara nåbara behöver de också vara utmanande. Vi vet att utmanande mål kan förbättra prestationen genom ökad självtillfredsställelse och motivation till att hitta lämpliga strategier för att driva våra färdigheter till det yttersta. Mål som däremot ligger utanför våra förmågor kommer däremot leda till känslor av missnöje och frustration.</p>
            <h3>4. Komplexitet</h3>
            <p>Om våra mål är för komplexa och ligger utanför vår förmågor kan de lätt bli överväldigande och negativt påverka vår produktivitet och motivation. När det gäller målets tidsplan så behöver den också vara realistisk, och vi måste ge oss tillräckligt med tid att arbeta mot ett mål för att det skall gå att uppnå.</p>
            <h3>5. Feedback</h3>
            <p>Målsättning är alltid mer effektiv om vi får en omedelbar feedback från vårt arbete. Feedback hjälper oss att avgöra i vilken utsträckning ett mål kan uppnås och hur du utvecklas. Feedback hjälper oss också att reflektera över vår förmåga och kan hjälpa oss att sätta nya mer uppnåbara mål.</p>
            <img src={successImage} alt="målsättning" className="imageFullWidth" />
            <h2>Att sätta personliga mål</h2>
            <p>Att sätta upp personliga mål innebär att du skapar din stora "bild" av vad du vill göra med ditt liv, eller kanske över de kommande 10 åren. Du identifierar sedan de målen som du vill uppnå, och bryter ner dessa i mindre och mindre mål som du behöver klara för att uppnå dina livsmål. Nästa steg är sedan att sätta upp ett plan och att börja arbeta mot dina mål. </p>
            <p>När vi sätter personliga mål brukar vi ofta sätta mål inom de olika områden i vårt liv som vi anser är viktigt för oss. För att ge dig några exempel på olika områden:</p>
            <ul>
              <li><b>Karriär</b> - Vilken nivå vill du nå i din karriär, eller vad vill du uppnå?</li>
              <li><b>Ekonomiskt</b> - Hur mycket vill du tjäna, i vilket skede? Hur vill du leva ekonomiskt?</li>
              <li><b>Utbildning</b> - Är det någon kunskap du vill förvärva? Vilken information och kompetens behöver du ha för att nå andra mål?</li>
              <li><b>Familj</b> - Vill du bli förälder? Om så är fallet, hur ska du bli en bra förälder? Vilka sorts relation vill du ha med andra familjemedlemmar?</li>
              <li><b>Fysiskt</b> - Finns det några atletiska mål som du vill uppnå, eller vill du ha god hälsa långt in i åldern? Vilka steg kommer du att ta för att uppnå detta?</li>
            </ul>
            <GatsbyImage image={data.personalgoals?.childImageSharp.gatsbyImageData} alt="personliga mål och områden i livet" />
            <br />
            <p>Försök att lägga ner lite tid på att brainstorma kring dessa områden och välj sedan ett eller flera mål i varje kategori som bäst återspeglar vad du vill göra eller uppnå. Men försök samtidigt att trimma ner antal mål så mycket som möjligt så att du endast tar med de viktigaste målen som du vill fokusera på.</p>
            <h2>Vilka olika typer av mål finns?</h2>
            <p>Det finns många olika typer av mål inom målsättning, såsom tidsmål, fokusmål, ämnesbaserade mål och beteendemål. I många fall kan ett mål innefattas i flera av kategorierna, men det gör inte alltid det.</p>
            <h3>1. Tidsmål</h3>
            <p>Tidsmål är kopplade till ett datum och när målet skall vara uppnått. Tidsmål kan antingen vara kortsiktiga eller långsiktiga. Långsiktiga mål tar ofta flera år att genomföra medan ett kortsiktigt mål kan ta veckor eller månader att klara. Det finns däremot ingen klar definition när ett kortsiktigt mål övergår till ett långsiktigt mål.</p>
            <h3>2. Fokusmål</h3>
            <p>Fokusmål är i många fall riktigt stora mål i våra livet, och tenderar därför falla in inom den långsiktiga kategorin. Fokusmål säger ofta vad vi skall fokusera på och kan vara något som att "jag skall skriva min första bok och publicera den i slutet av året" eller "jag skall springa mitt första maraton".</p>
            <h3>3. Ämnesbaserade mål</h3>
            <p>Ämnesbaserade mål kan kopplas till ett specifikt område i ditt liv, såsom din karriär eller ekonomi. När vi arbetar mot våra mål så har vi ofta olika ämnesbaserade mål inom olika områden, exempelvis kan vi ha ett mål såsom "jag skall spara 1000kr varje månad" medan vi har ett mål såsom "jag skall gå ner X kg i vikt". Helst skall vi försöka att inte arbeta mot för många mål inom samma kategori samtidigt. Vi bör prioritera våra ämnesbaserade mål och börja med det som vi anser är mest viktigt.</p>
            <h3>4. Beteendemål</h3>
            <p>Ett beteendemål är kopplat till ett visst beteende eller en vana som vi vill utveckla. Fördelen med ett beteendemål är att det skapar en konkret bild om vad som skall göras, och kan ofta vara till grund för ett annat mål som vi vill uppnå.</p>
            <p>Säg att vi exempelvis vill springa ett maraton och har det som mål. Ett effektivt sätt att uppnå målet är då att bryta ner det och identifiera vilka <a href="/vanor/">vanor</a> och vilket beteende vi måste bygga för att kunna uppnå vårt långsiktiga mål.</p>
            <h2>Långsiktiga och kortsiktiga mål</h2>
            <p>Det är viktigt att vi har både långsiktiga och kortsiktiga mål, eftersom det är två olika typer av mål som fyller olika syften. De långsiktiga målen ger oss en långsiktig riktning dit vi är påväg, medan de kortsiktiga målen ger oss snabb feedback och har en lägre svårighetsgrad.</p>
            <p>Långsiktiga mål är mål som du vill uppnå senare <b>i framtiden</b>. De är ofta kopplade till att lyckas på något sätt i ditt liv, och kan vara personliga eller kopplade till ditt yrkesliv. Långsiktiga mål tar ofta flera år (såsom 2-5 år) att slutföra, men vanligtvis kommer du inte att arbeta med dem hela livet. Detta är också hur långsiktiga mål skiljer sig från livsmål, långsiktiga mål kräver nämligen inte livslånga ansträngningar.</p>
            <p>Kortsiktiga mål ger oss <b>snabbare feedback</b> och kan sträcka sig från en vecka till månader. De kortsiktiga målen är ofta nedbrutna långsiktiga mål så att de skall vara lättare att gör de lättare uppnå i flera steg. Detta beror som sagt på att de kortsiktiga målen ger oss snabbare feedback, vilket också gör att de kan ge oss större positiva känslor och ökad motivation att sätta ytterligare mål när vi klarar dem. Kortsiktiga mål gör det också enklare att granska och validera att vi uppnått ett önskat resultat.</p>

            <h2>SMART: Ett ramverk för att sätta mål</h2>
            <p>Smart modellen kännetecknas av fem principer; specifikt, mätbart, accepterat, realistiskt och tids-relaterat. Tillsammans bildar dessa fem principer ordet SMART och det är anledning till varför det kallas för smarta mål.</p>
            <p>Smarta mål kan hjälpa oss att arbeta mer systematiskt med vår målsättning vilket kan öka sannolikheten för att vi uppnår våra mål och dessutom kan smarta mål bidra med mycket motivation. De fem principer som smarta mål kännetecknas av är:</p>
            <ul>
              <li><b>Specifikt - </b>Specificera vad som skall uppnås.</li>
              <li><b>Mätbart - </b>Kvantifiera eller föreslå en indikator på att mäta framsteg.</li>
              <li><b>Accepterat - </b>Målet skall accepteras av alla inblandade. </li>
              <li><b>Realistiskt - </b>Målet skall vara realistiskt och nåbart givet dina nuvarande resurser. </li>
              <li><b>Tids-relaterat – </b>Specificera när målet skall vara uppnått.</li>
            </ul>
            <p><Link to="/målsättning/smart">SMART</Link> kan vara en bra metod för att sätta upp mål och modellen kan dessutom ge mycket klarhet och motivation. Den kan också hjälpa dig att klarare definiera ditt mål som du själv vet vad du satsar efter. Modellen är dessutom mycket enkel att använda, vilken är en stor fördel.</p>
            <p>Vissa hävdar dessutom att modellen har en dålig flexibilitet, vilket många mål ofta kan behöva. När man arbetar för att nå sina mål kan det ibland behövas att man omdefinierar målen när man har mer information. Eller så kanske man inser att det man först ville uppnå inte var så viktigt trots allt.</p>
            <p>Om du vill läsa mer hur man kan sätta smarta mål och vill ha konkreta exempel kan du <Link to={"/målsättning/smart/"}>klicka här.</Link></p>
            <h2>NÖHRA-modellen: Ett alternativ till smarta mål</h2>
            <p>NÖHRA är en annan modell för att sätta mål och skapa förändring. NÖHRA kännetecknas av de fem principerna; Nuläge, Önskat läge, Hinder, Resurser och Aktiviteter. Modell handlar mycket om att ta fram en plan till vart man vill ta sig, och identifiera olika hinder som man stöta på under vägen. Varje steg i NÖHRA kännetecknas av att du ställer olika frågor till dig själv:</p>
            <ul>
              <li><b>Nuläge – </b>Vart är du idag?</li>
              <li><b>Önskat läge – </b>Vart är målet? Vart vill du ta dig?</li>
              <li><b>Hinder – </b>Vilka hinder står i vägen för ditt/dina mål?</li>
              <li><b>Resurser – </b>Vilka resurser har du själv? Vilka kan hjälpa dig och hur?</li>
              <li><b>Aktiviteter – </b>Hur kan du gå vidare för att ta dig till önskat läge?</li>
            </ul>
            <p>NÖHRA-modellen handlar om att man skall ta fram en tydlig handlingsplan för hur man skall kunna uppnå sina mål. Det handlar om att identifiera hinder på vägen och viktiga steg som du behöver ta för att uppnå ditt önskade läge. </p>
            <p>NÖHRA är ofta ett verktyg som används inom många olika områden; såsom när man skall coacha någon annan, i företagsmöten, eller i ditt vardagslivet.</p>
            <GatsbyImage image={data.nohra?.childImageSharp.gatsbyImageData} alt="nöhra-modellen" />
            <h2>Andra tips för att uppnå dina mål</h2>
            <p>Här kommer några andra tips för att sätta bättre mål och för att du skall lyckas att uppnå dem. Det finns mycket som tyder på att vi bör fokusera på våra kortsiktiga mål och arbeta mot dem eftersom vi snabbt kommer se resultat, vilket kommer öka vår motivation. </p>
            <p>Detta innebär däremot inte att vi inte bör ha långsiktiga mål. Vi bör ha och sätta långsiktiga mål, men vi bör istället använda våra långsiktiga mål för att kunna sätta kortsiktiga mål. Och att genom att uppnå många av våra kortsiktiga mål kommer vi tillslut nå vårt långsiktiga mål. </p>
            <p>Vi bör också göra våra mål positiva och omforma mål såsom "jag vill sluta äta så mycket skräpmat" till något positivt såsom "jag vill känna mig hälsosam och kommer förändra min kost för att uppnå det". </p>
            <p>Vi bör dessutom skriva ner våra mål, för forskningen säger att du är 42 procent mer benägna att uppnå dina mål om du skriver ner dem. Att skriva ner dina mål tvingar dig inte bara att bli tydlig med vad du vill uppnå, utan det spelar också en roll i att motivera dig att slutföra de uppgifter som är nödvändiga för att du skall lyckas.</p>
            <br />
          </article>
        </div>
      </Page>
    </>
  );
}

export const query = graphql`
  query {
    image: file(relativePath: { eq: "goalsImage.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    },
    nohra: file(relativePath: { eq: "nohra.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    },
    personalgoals: file(relativePath: { eq: "personalgoals.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    principer: file(relativePath: { eq: "principer-goals.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
  }
`;
